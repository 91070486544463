@import './variables';

.bg-color-red {
  background-color: $primary-color-red !important;
}

.bg-color-yellow {
  background-color: $primary-color-yellow !important;
}

.bg-color-blue-light {
  background-color: $primary-color-blue-light !important;
}

.bg-color-blue-dark {
  background-color: $primary-color-blue-dark !important;
}

.bg-color-gray {
  background-color: $primary-color-gray !important;
}

.color-red {
  color: $primary-color-red !important;
}

.color-yellow {
  color: $primary-color-yellow !important;
}

.color-blue-light {
  color: $primary-color-blue-light !important;
}

.color-blue-dark {
  color: $primary-color-blue-dark !important;
}

.App {
  text-align: center;
  //font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}

h1, h2, h3, h4 {
  line-height: 1.1em;
  //font-family: $montserratFont;
}

.font-white {
  color: white!important;
}

.font-black {
  color: black!important;
}

.font-light-blue {
  color: $primary-color-blue-light!important;
}

.btn-uniform {
  text-transform: uppercase;
  letter-spacing: 2px;
  //font-family: $montserratFont;
}