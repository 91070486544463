@import '../../../variables.scss';

.careers-banner-content {
    background-image: url("../../../assets/img/about-us-2.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .careers-banner-container {
        position: relative;
        width: 100%;
        height: 500px;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        h1 {
            // text-transform: uppercase;
            font-size: 2.4em;
            font-weight: 700;
            padding-bottom: 20px;
            padding-top: 25px;
        }
        p {
            // width: 720px;
            width: 80%;
            margin: auto;
            // font-size: 14px;
            // font-size: 1.2em;
        }
        
    }

    @media only screen and (min-width: 580px) {
        .careers-banner-container {        
            h1 {
                font-size: 2.2em;
            }
            p {
            }
    }

    @media only screen and (min-width: 767px) {
        .careers-banner-container {        
            h1 {
                font-size: 2.6em;
            }
            p {
                width: 720px;
            }
        }
    }
    
    @media only screen and (min-width: 1024px) {        
        .careers-banner-container {        
            h1 {
                font-size: 3em;
            }
            p {
            }
        }
    }
    
    @media only screen and (min-width: 1200px) {        
        .careers-banner-container {        
            h1 {
                font-size: 5em;
            }
            p {
                width: 800px;
            }
        }
    }

    @media only screen and (min-width: 1440px) {        
        .careers-banner-container {        
            h1 {
                font-size: 5em;
            }
            p {
                width: 900px;
            }
        }
    }
}
}