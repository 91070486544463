@import '../../../variables.scss';

.we-like-to-keep-content {
    background-color: #282828;
    // padding: 30px;
    // margin: 10px;
    text-align: center;
    height: 348px;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    
    img, p {
        transition: opacity 0.5s ease-in-out;
        width: 225px;
        height: auto;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        top: 0; 
        left: 0; 
        opacity: 1;
        display: block;
        margin: auto;
        width: 100%;
    }

    @media only screen and (min-width: 768px) {
        img {
            width: 290px;
        }
    }
    @media only screen and (min-width: 1200px) {
        img {
            width: 300px;
        }
    }
}
