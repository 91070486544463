@import '../../variables.scss';

.we-like-to-keep-container {
    background-image: url("../../assets/img/bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #0d0d0d;
    background-size: cover;
    // padding-top: 200px;
    // padding-bottom: 200px; 
    width: 100%;
    padding-bottom: 100px;

    .we-like-to-keep-content-wrapper {
        justify-content: center;
    }

    @media only screen and (min-width: 992px) {
        background-position: top;
        background-size: auto auto;
    }
}