@import '../../../variables.scss';

.info-peek-home-wrapper {
    position: relative;
    height: auto;
    overflow: hidden;
    background-image: url("../../../assets/img/home-bg-banner-mobile.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $primary-video-bg-color;

    video {
        opacity: 0.4;
    }

    .info-peek-home {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 100px;
        padding-bottom: 100px;

        svg {
            font-size: 40px;
            margin-bottom: 2%;
        }

        a { 
            padding: 10px 20px;
            position: relative;
            overflow: hidden;
        }

        .info-peek-home-title {
            font-size: 1.5em;
            font-weight: 700;
            //font-family: $montserratFont;
        }
    }
    @media only screen and (min-width: 580px) {
        
        .info-peek-home {
            .info-peek-home-title {
                font-size: 2.5em;
            }
            .info-peek-home-content { 
                font-size: 1.2m; 
            }
            .info-peek-home-add-content {
                font-size: 1.2em; 
            }     
            svg { font-size: 50px; }
        }
    }

    @media only screen and (min-width: 767px) {
        background: none;
        background-color: $primary-video-bg-color;
        .info-peek-home {
            height: 100vh;
            padding-top: 0;
            padding-bottom: 0;

            .info-peek-home-title {
                font-size: 3em;
            }
            svg { font-size: 70px; }
            // .info-peek-home-content { font-size: 16px; }
            // .info-peek-home-add-content { font-size: 16px; }
        }
    }
    @media only screen and (min-width: 1200px) {
        .info-peek-home {
            
            .info-peek-home-title {
                font-size: 5em;
            }
            svg { font-size: 90px; }
            .info-peek-home-content { font-size: 1.2em; }
            .info-peek-home-add-content { font-size: 1.2em; margin-bottom: 40px; }
        }
    }
}



    
    
    