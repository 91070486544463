body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.6;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  font-weight: 300; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.bg-color-red {
  background-color: #df5745 !important; }

.bg-color-yellow {
  background-color: #fbba4a !important; }

.bg-color-blue-light {
  background-color: #00ccff !important; }

.bg-color-blue-dark {
  background-color: #183858 !important; }

.bg-color-gray {
  background-color: rgba(255, 255, 255, 0.33) !important; }

.color-red {
  color: #df5745 !important; }

.color-yellow {
  color: #fbba4a !important; }

.color-blue-light {
  color: #00ccff !important; }

.color-blue-dark {
  color: #183858 !important; }

.App {
  text-align: center;
  overflow: hidden; }

h1, h2, h3, h4 {
  line-height: 1.1em; }

.font-white {
  color: white !important; }

.font-black {
  color: black !important; }

.font-light-blue {
  color: #00ccff !important; }

.btn-uniform {
  text-transform: uppercase;
  letter-spacing: 2px; }

.header {
  width: 100%;
  z-index: 9;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between; }
  .header #myHeader {
    height: 60px;
    padding-top: 16px; }
    .header #myHeader .site-branding {
      text-align: left; }
      .header #myHeader .site-branding img {
        width: 100%;
        max-width: 150px;
        height: auto; }
    .header #myHeader .toggle-btn {
      color: white;
      position: absolute;
      right: 20px;
      top: 0; }
  .header .sidenav {
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    top: -100%;
    right: -100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #df5745;
    overflow-x: hidden; }
    .header .sidenav .close-btn {
      position: absolute;
      right: 25px;
      top: 10px; }
    .header .sidenav .link-container {
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center; }
    .header .sidenav a {
      text-decoration: none;
      font-size: 2.5em;
      color: white;
      display: block;
      font-weight: 700; }
    .header .sidenav a:hover {
      color: #cccccc; }
  .header .openNav {
    right: 0;
    top: 0;
    transition-duration: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    display: flex; }
  .header .closeNav {
    right: -100%;
    transition-duration: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s; }
  @media screen and (min-width: 580px) {
    .header #myHeader .site-branding img {
      max-width: 250px; }
    .header .sidenav a {
      font-size: 2.7em; } }
  @media screen and (min-width: 768px) {
    .header #myHeader .site-branding img {
      max-width: 300px; }
    .header .sidenav {
      height: 100%;
      justify-content: center; }
      .header .sidenav a {
        font-size: 1.6em; }
      .header .sidenav .link-container {
        height: auto;
        width: 90%;
        flex-direction: row;
        justify-content: space-around;
        align-items: center; } }
  @media screen and (min-width: 1024px) {
    .header #myHeader .site-branding img {
      max-width: 270px; }
    .header .sidenav a {
      font-size: 2.1em; } }
  @media screen and (min-width: 1200px) {
    .header .sidenav a {
      font-size: 2.5em; } }
  @media screen and (min-width: 1440px) {
    .header .sidenav a {
      font-size: 2.8em; } }
  @media screen and (min-width: 1700px) {
    .header .sidenav a {
      font-size: 3em; } }

.sticky {
  position: fixed;
  top: 0;
  background-color: #0e1323;
  animation: smoothScroll 1.2s forwards; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-100px); }
  100% {
    transform: translateY(0px); } }

.dissapeared {
  position: fixed;
  background-color: #0e13239e;
  animation: smoothScroll 1.2s forwards; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-100px); }
  100% {
    transform: translateY(0px); } }

.layout-content {
  position: relative;
  top: 0; }

footer {
  position: relative;
  width: 100%;
  height: auto;
  clear: both;
  background-color: #f5f5f5; }
  footer .footer-links {
    margin-top: 20px; }
    footer .footer-links a {
      list-style: none;
      display: block;
      margin: 15px 0px;
      font-size: 16px;
      line-height: 27px;
      color: #969696;
      font-weight: 300; }
  footer .footer-logo {
    margin: 10px 0; }
    footer .footer-logo img {
      height: auto;
      width: 25%;
      min-width: 150px; }
  @media only screen and (min-width: 769px) {
    footer {
      height: 170px; }
      footer .footer-links {
        margin-top: 40px; }
        footer .footer-links a {
          display: inline;
          margin: 0 25px; }
      footer .footer-logo {
        margin: 20px 0; }
        footer .footer-logo img {
          height: auto;
          width: 25%; } }

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-image: url(/static/media/mobile-bg-2.a4d1bcac.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px; }
  .component video {
    opacity: 0.4; }
  .component .home-main-section {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    text-align: center; }
    .component .home-main-section .heading {
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .component .home-main-section .heading p {
        font-weight: 700;
        line-height: 1.1em;
        margin: .27em 0;
        font-size: 2.4em; }
    .component .home-main-section .home-content-section {
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .component .home-main-section .home-content-section h3 {
        font-weight: 700 !important;
        line-height: 1.5em;
        font-size: 0.9em; }
  @media only screen and (min-width: 415px) {
    .component .home-main-section .heading p {
      font-size: 2.5em;
      max-width: 350px;
      display: table;
      margin-left: auto;
      margin-right: auto; }
    .component .home-main-section .home-content-section {
      width: 65%; } }
  @media only screen and (min-width: 520px) {
    .component .home-main-section .heading p {
      font-size: 3.5em;
      max-width: 450px; }
    .component .home-main-section .home-content-section {
      width: 65%; } }
  @media only screen and (min-width: 838px) {
    .component .home-main-section .heading {
      width: 60%; }
    .component .home-main-section .home-content-section h3 {
      font-size: 1.3em; } }
  @media only screen and (min-width: 768px) {
    .component {
      background: none;
      background-color: #03090e;
      height: 100vh;
      padding-top: 0;
      padding-bottom: 0; }
      .component .home-main-section .heading {
        width: 65%; }
      .component .home-main-section .home-content-section h3 {
        font-size: 1.3em; } }
  @media only screen and (min-width: 1256px) {
    .component .home-main-section .heading p {
      font-size: 5.2em;
      max-width: 550px; }
    .component .home-main-section .home-content-section {
      width: 38%; } }

.video-container {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%); }
  @media only screen and (min-width: 767px) {
    .video-container {
      display: block; } }

.services-container {
  height: auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  background-color: #f9f9f9; }
  .services-container .consulting-services-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 6% 0;
    margin: 1% 0;
    color: #183858; }
    .services-container .consulting-services-heading h1 {
      font-weight: 700;
      line-height: 1.2em;
      font-size: 1.5em; }
      .services-container .consulting-services-heading h1 span {
        display: block;
        margin-top: 10px;
        font-size: 0.7em; }
  .services-container .consulting-services-box-wrapper {
    height: 100%;
    min-width: 200px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 1em;
    padding: 7% 6% 0 6%;
    font-size: 1em;
    text-align: left; }
    .services-container .consulting-services-box-wrapper svg {
      font-size: 2.5rem; }
    .services-container .consulting-services-box-wrapper .consulting-services-box {
      padding: 11% 0;
      margin-bottom: 5%; }
      .services-container .consulting-services-box-wrapper .consulting-services-box .consulting-services-box-title {
        font-weight: 700;
        margin-top: 10%;
        margin-bottom: 3%;
        font-size: 1.8em; }
  @media only screen and (min-width: 768px) {
    .services-container {
      width: 100%;
      padding: 100px; }
      .services-container .consulting-services-heading {
        padding: 3% 0; }
        .services-container .consulting-services-heading h1 {
          font-size: 2.2em; }
      .services-container .consulting-services-box-wrapper svg {
        margin-bottom: 8%; }
      .services-container .consulting-services-box-wrapper .consulting-services-box .consulting-services-box-title {
        font-size: 1.6em;
        max-width: 150px; } }
  @media only screen and (min-width: 1024px) {
    .services-container .consulting-services-heading h1 {
      font-size: 2.2em; }
    .services-container .consulting-services-box-wrapper .consulting-services-box .consulting-services-box-title {
      font-size: 1.8em;
      max-width: 150px; } }
  @media only screen and (min-width: 1200px) {
    .services-container .consulting-services-heading h1 {
      font-size: 2.2em; }
    .services-container .consulting-services-box-wrapper .consulting-services-box .consulting-services-box-title {
      font-size: 2.2em;
      max-width: 150px; } }

.app-dev-container {
  height: auto;
  position: relative;
  top: -1px;
  display: flex;
  z-index: 1;
  background-color: white; }
  .app-dev-container .app-development-cards {
    margin-top: 50px;
    margin-bottom: 50px; }
    .app-dev-container .app-development-cards .app-dev-box1 {
      text-align: center;
      border-bottom: 1px solid #EBEBEB; }
      .app-dev-container .app-development-cards .app-dev-box1 svg {
        font-size: 2.5em;
        float: left;
        color: #183858;
        transform: rotate(90deg); }
      .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-title {
        margin-top: 50px;
        font-size: 1.5m;
        font-weight: 700;
        color: #183858;
        margin-bottom: 0;
        line-height: 1.2em; }
      .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-content {
        margin-top: 10%;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5;
        width: 92%;
        margin-left: auto;
        margin-right: auto; }
    .app-dev-container .app-development-cards .app-dev-box {
      min-width: 200px;
      width: 100%;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      font-size: 1em;
      height: 100%;
      margin-top: 15%; }
      .app-dev-container .app-development-cards .app-dev-box svg {
        font-size: 1.7em;
        margin-left: 4%; }
      .app-dev-container .app-development-cards .app-dev-box .app-development-title {
        margin-bottom: 0.27em;
        margin-top: 14%;
        font-weight: 700;
        line-height: 1.2em;
        font-size: 1.2em; }
      .app-dev-container .app-development-cards .app-dev-box .app-development-content {
        margin-top: 2%;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5; }
    .app-dev-container .app-development-cards .hl-dev {
      width: 15%;
      background-color: white;
      height: 2px; }
  @media only screen and (min-width: 415px) {
    .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-title {
      font-size: 2em; }
    .app-dev-container .app-development-cards .app-dev-box {
      margin-top: 10%; } }
  @media only screen and (min-width: 580px) {
    .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-title {
      font-size: 2.6em; } }
  @media only screen and (min-width: 769px) {
    .app-dev-container .app-development-cards {
      margin-top: 100px;
      margin-bottom: 100px; }
      .app-dev-container .app-development-cards .app-dev-box1 svg {
        font-size: 3.5em; }
      .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-title {
        font-size: 2.4em; } }
    @media only screen and (min-width: 769px) and (min-width: 1399px) {
      .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-title {
        font-size: 3.6em; } }
  @media only screen and (min-width: 769px) {
      .app-dev-container .app-development-cards .app-dev-box1 .app-dev-left-content {
        margin-top: 3%;
        margin-bottom: 6%;
        width: 69%; }
      .app-dev-container .app-development-cards .app-dev-box {
        margin-top: 30%; }
        .app-dev-container .app-development-cards .app-dev-box svg {
          font-size: 1.9em; } }

a#hover-button-effect {
  position: relative;
  overflow: hidden;
  background: #fbba4a;
  border: none;
  padding: 17px 27px;
  transition: all 1s ease-in-out;
  color: white; }

#hover-button-effect .button-text {
  position: absolute;
  opacity: 0;
  color: white;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding-top: 18px;
  z-index: 1000; }

a#hover-button-effect:hover .button-text {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.2s; }

#hover-button-effect .button-hover-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  opacity: 0;
  background-color: #df5745;
  z-index: 10;
  transition: all 0.5s ease-in-out; }

a#hover-button-effect:hover .button-hover-bg {
  width: 100%;
  height: 100%;
  left: 0%;
  opacity: 1;
  position: absolute;
  padding: 10px 20px;
  background-color: #df5745;
  border: none;
  transition: all 0.5s ease-in-out; }

.info-peek-home-wrapper {
  position: relative;
  height: auto;
  overflow: hidden;
  background-image: url(/static/media/home-bg-banner-mobile.e5a46d91.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #03090e; }
  .info-peek-home-wrapper video {
    opacity: 0.4; }
  .info-peek-home-wrapper .info-peek-home {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 100px; }
    .info-peek-home-wrapper .info-peek-home svg {
      font-size: 40px;
      margin-bottom: 2%; }
    .info-peek-home-wrapper .info-peek-home a {
      padding: 10px 20px;
      position: relative;
      overflow: hidden; }
    .info-peek-home-wrapper .info-peek-home .info-peek-home-title {
      font-size: 1.5em;
      font-weight: 700; }
  @media only screen and (min-width: 580px) {
    .info-peek-home-wrapper .info-peek-home .info-peek-home-title {
      font-size: 2.5em; }
    .info-peek-home-wrapper .info-peek-home .info-peek-home-content {
      font-size: 1.2m; }
    .info-peek-home-wrapper .info-peek-home .info-peek-home-add-content {
      font-size: 1.2em; }
    .info-peek-home-wrapper .info-peek-home svg {
      font-size: 50px; } }
  @media only screen and (min-width: 767px) {
    .info-peek-home-wrapper {
      background: none;
      background-color: #03090e; }
      .info-peek-home-wrapper .info-peek-home {
        height: 100vh;
        padding-top: 0;
        padding-bottom: 0; }
        .info-peek-home-wrapper .info-peek-home .info-peek-home-title {
          font-size: 3em; }
        .info-peek-home-wrapper .info-peek-home svg {
          font-size: 70px; } }
  @media only screen and (min-width: 1200px) {
    .info-peek-home-wrapper .info-peek-home .info-peek-home-title {
      font-size: 5em; }
    .info-peek-home-wrapper .info-peek-home svg {
      font-size: 90px; }
    .info-peek-home-wrapper .info-peek-home .info-peek-home-content {
      font-size: 1.2em; }
    .info-peek-home-wrapper .info-peek-home .info-peek-home-add-content {
      font-size: 1.2em;
      margin-bottom: 40px; } }

.info-peek-about-wrapper {
  height: auto;
  background-image: url(/static/media/mobile-bg-2.a4d1bcac.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .info-peek-about-wrapper .info-peek-about {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px; }
    .info-peek-about-wrapper .info-peek-about a {
      padding: 10px 20px;
      font-size: 0.8em; }
    .info-peek-about-wrapper .info-peek-about .info-peek-about-title {
      font-size: 1.8em;
      font-weight: 700; }
    .info-peek-about-wrapper .info-peek-about .info-peek-about-content {
      margin-bottom: 2%;
      margin-top: 1%;
      font-size: 1.1em;
      padding-left: 2%;
      padding-right: 2%;
      font-size: 0.8em; }
      .info-peek-about-wrapper .info-peek-about .info-peek-about-content h2 {
        font-size: 3.5em; }
  @media only screen and (min-width: 580px) {
    .info-peek-about-wrapper .info-peek-about .info-peek-about-title {
      font-size: 2em; }
    .info-peek-about-wrapper .info-peek-about .info-peek-about-content {
      font-size: 1.1em; } }
  @media only screen and (min-width: 768px) {
    .info-peek-about-wrapper .info-peek-about {
      max-width: 850px;
      height: 80vh; }
      .info-peek-about-wrapper .info-peek-about a {
        font-size: 1.1em; }
      .info-peek-about-wrapper .info-peek-about .info-peek-about-title {
        font-size: 3.5em; }
      .info-peek-about-wrapper .info-peek-about .info-peek-about-content {
        font-size: 1.1em; } }

.about-text-container {
  height: auto;
  padding-bottom: 14%;
  display: flex;
  flex-direction: column;
  text-align: left; }
  .about-text-container h2 {
    font-size: 1.2em;
    font-weight: 700;
    max-width: 250px;
    padding: 20px 0; }
  @media only screen and (min-width: 415px) {
    .about-text-container {
      padding-bottom: 7%; }
      .about-text-container h2 {
        max-width: 350px;
        padding: 40px 0;
        font-size: 2em; } }
  @media only screen and (min-width: 769px) {
    .about-text-container {
      padding-bottom: 6%; }
      .about-text-container h2 {
        max-width: 500px;
        font-size: 2.6em;
        padding: 60px 0; } }

.about-banner-container {
  background-image: url(/static/media/about-1.28fbb782.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 5% 0; }
  .about-banner-container .about-banner-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px; }
    .about-banner-container .about-banner-text-container .about-banner-box-container .about-banner-box {
      display: flex;
      flex-direction: column; }
    .about-banner-container .about-banner-text-container h1 {
      font-weight: 700;
      font-size: 2.4em;
      padding-bottom: 2%;
      margin-top: 10%;
      text-align: center; }
    .about-banner-container .about-banner-text-container h3 {
      text-align: center;
      font-size: 1.1em;
      font-weight: 700;
      margin-bottom: 15px;
      padding: 5px;
      word-spacing: 9999px; }
    .about-banner-container .about-banner-text-container p {
      line-height: 1.5;
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 5%;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
    .about-banner-container .about-banner-text-container .subheader {
      margin-top: 30%;
      margin: 0;
      margin-bottom: 30px; }
      .about-banner-container .about-banner-text-container .subheader h2 {
        font-weight: 700;
        font-size: 1.2em; }
  @media only screen and (min-width: 768px) {
    .about-banner-container {
      padding-top: 0;
      padding-bottom: 60px; }
      .about-banner-container .about-banner-text-container {
        padding-top: 0; }
        .about-banner-container .about-banner-text-container h1 {
          font-size: 5rem;
          max-width: 600px;
          display: table;
          margin: 0 auto;
          margin-top: 150px; }
        .about-banner-container .about-banner-text-container .subheader h2 {
          margin-bottom: 60px;
          font-size: 2rem;
          padding-top: 50px; }
        .about-banner-container .about-banner-text-container h3 {
          font-size: 1.5rem; } }

.transform-container {
  padding-top: 50px;
  padding-bottom: 30px; }
  .transform-container .transform-heading {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 50px;
    font-size: 1.1em;
    margin-bottom: 20px; }
  .transform-container h4 {
    padding-bottom: 50px;
    padding-top: 50px;
    width: 80%;
    text-align: center;
    margin: 0 auto; }
  @media screena and (min-width: 415px) {
    .transform-container .transform-heading {
      font-size: 1.4em; } }
  @media screen and (min-width: 769px) {
    .transform-container {
      padding-top: 70px;
      padding-bottom: 50px; } }
  @media screen and (min-width: 992px) {
    .transform-container {
      padding-top: 70px;
      padding-bottom: 50px; }
      .transform-container .transform-heading {
        font-size: 2em;
        margin-bottom: 50px; } }

.testimonial-container {
  padding-bottom: 30px; }
  .testimonial-container .center-testimonial {
    padding-left: 3%;
    padding-right: 3%;
    margin: 0 auto; }
    .testimonial-container .center-testimonial .testimonial-body {
      margin-top: 50px;
      margin-bottom: 50px; }
  @media screen and (min-width: 769px) {
    .testimonial-container {
      padding-top: 80px;
      padding-bottom: 80px; } }

.careers-content .open-positions-heading {
  color: #183858; }

@media only screen and (min-width: 768px) {
  .careers-content .open-positions-heading {
    font-size: 2.2em !important;
    text-align: center !important; } }

#hiringthing-jobs .ht-summary {
  max-width: 100%; }

#hiringthing-jobs .ht-location {
  font-size: 1em;
  margin-bottom: 10px; }

#hiringthing-jobs .ht-apply-link {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 16px;
  background-color: #17a2b8;
  border-color: #17a2b8;
  padding: 6px 12px;
  color: white;
  border-radius: 3px; }
  #hiringthing-jobs .ht-apply-link:hover {
    text-decoration: none !important;
    background-color: #138496;
    border-color: #117a8b; }

#hiringthing-jobs .ht-title-link {
  display: block;
  font-size: 1.4em;
  font-weight: 500;
  color: #183858;
  padding-top: 35px;
  box-shadow: 0 -4px 4px -4px lightgrey; }
  #hiringthing-jobs .ht-title-link:hover {
    text-decoration: none !important; }
  #hiringthing-jobs .ht-title-link:first-child {
    box-shadow: none; }

.open-positions {
  margin-bottom: 7%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .open-positions .error-message {
    color: red; }
  .open-positions .position-list {
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .open-positions .position-list .position-wrapper {
      background-color: white;
      margin: 1% 1%;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08); }
      .open-positions .position-list .position-wrapper .position {
        margin: 4%; }
        .open-positions .position-list .position-wrapper .position img {
          width: auto;
          max-width: 200px; }
        .open-positions .position-list .position-wrapper .position p {
          font-size: 0.9em;
          line-height: 1.6;
          font-weight: 400; }
        .open-positions .position-list .position-wrapper .position .position-title {
          font-size: 1.4em;
          font-weight: 700;
          margin-top: 30px; }
        .open-positions .position-list .position-wrapper .position .resposibilities, .open-positions .position-list .position-wrapper .position .skills, .open-positions .position-list .position-wrapper .position .bonus {
          margin: 2% 0; }
          .open-positions .position-list .position-wrapper .position .resposibilities .list-header, .open-positions .position-list .position-wrapper .position .skills .list-header, .open-positions .position-list .position-wrapper .position .bonus .list-header {
            font-size: 1em;
            font-weight: 700; }
          .open-positions .position-list .position-wrapper .position .resposibilities .skills-title, .open-positions .position-list .position-wrapper .position .skills .skills-title, .open-positions .position-list .position-wrapper .position .bonus .skills-title {
            font-size: 1em;
            font-weight: 500; }
          .open-positions .position-list .position-wrapper .position .resposibilities .careers-list, .open-positions .position-list .position-wrapper .position .skills .careers-list, .open-positions .position-list .position-wrapper .position .bonus .careers-list {
            list-style: none;
            -webkit-padding-start: 10px;
                    padding-inline-start: 10px;
            font-size: 0.9em;
            line-height: 30px;
            font-weight: 400; }
        .open-positions .position-list .position-wrapper .position .btn-wrapper {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: center; }
          .open-positions .position-list .position-wrapper .position .btn-wrapper .apply-btn {
            background-color: #4bc1d3;
            align-self: center;
            margin-top: 15px;
            border: solid 1px #3ca9b7; }
          .open-positions .position-list .position-wrapper .position .btn-wrapper .apply-btn:hover {
            background-color: #3ca9b7;
            align-self: center;
            border: solid 1px #4bc1d3; }
  @media only screen and (min-width: 768px) {
    .open-positions {
      flex-direction: column; }
      .open-positions .position-list {
        flex-direction: row; } }

.careers-banner-content {
  background-image: url(/static/media/about-us-2.11f2b735.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .careers-banner-content .careers-banner-container {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .careers-banner-content .careers-banner-container h1 {
      font-size: 2.4em;
      font-weight: 700;
      padding-bottom: 20px;
      padding-top: 25px; }
    .careers-banner-content .careers-banner-container p {
      width: 80%;
      margin: auto; }
  @media only screen and (min-width: 580px) {
    .careers-banner-content .careers-banner-container h1 {
      font-size: 2.2em; } }

@media only screen and (min-width: 580px) and (min-width: 767px) {
  .careers-banner-content .careers-banner-container h1 {
    font-size: 2.6em; }
  .careers-banner-content .careers-banner-container p {
    width: 720px; } }

@media only screen and (min-width: 580px) and (min-width: 1024px) {
  .careers-banner-content .careers-banner-container h1 {
    font-size: 3em; } }

@media only screen and (min-width: 580px) and (min-width: 1200px) {
  .careers-banner-content .careers-banner-container h1 {
    font-size: 5em; }
  .careers-banner-content .careers-banner-container p {
    width: 800px; } }

@media only screen and (min-width: 580px) and (min-width: 1440px) {
  .careers-banner-content .careers-banner-container h1 {
    font-size: 5em; }
  .careers-banner-content .careers-banner-container p {
    width: 900px; } }

.careers-sidebar {
  text-align: left;
  margin-bottom: 8%;
  margin-bottom: 0;
  padding: 10px 20px; }
  .careers-sidebar span {
    color: #4bbfd2; }
  .careers-sidebar .careers-sidebar-heading {
    font-weight: 700;
    font-size: 1.8em; }
  .careers-sidebar .sidebar-text {
    font-weight: 300;
    margin-bottom: 4%;
    font-size: 1em; }
  .careers-sidebar .benefits-list-container {
    background-color: #4bc1d3;
    border-radius: 25px;
    color: white;
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 25px 50px 0px 25px; }
    .careers-sidebar .benefits-list-container .benefits-list-heading {
      font-weight: 700;
      font-size: 1.2em; }
    .careers-sidebar .benefits-list-container .benefits-list {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding-left: 0;
      margin-top: 3%; }
      .careers-sidebar .benefits-list-container .benefits-list li {
        padding-bottom: 10px;
        border-bottom: #5fc8d8 1px solid;
        margin-bottom: 20px;
        font-size: 1em;
        display: inline-block;
        width: 100%; }
      .careers-sidebar .benefits-list-container .benefits-list svg {
        margin-right: 2%; }
  @media screen and (min-width: 767px) {
    .careers-sidebar .benefits-list-container {
      margin-bottom: 60px;
      padding: 35px 50px 0px 50px; }
    .careers-sidebar .careers-sidebar-heading {
      font-size: 3.2em; }
    .careers-sidebar .sidebar-text {
      font-size: 1em; }
    .careers-sidebar .benefits-list-container {
      margin-top: 0px;
      padding: 35px 50px 0px 50px; }
      .careers-sidebar .benefits-list-container .benefits-list {
        flex-direction: row;
        flex-wrap: wrap; }
        .careers-sidebar .benefits-list-container .benefits-list li {
          width: 50%; } }

.careers-content {
  background-color: #f9f9f9;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 4%; }
  .careers-content .open-positions-heading {
    font-size: 1.1em;
    text-align: left;
    font-weight: 700;
    margin-bottom: 5%;
    margin-top: 10%;
    padding-bottom: 3%; }
  @media only screen and (min-width: 768px) {
    .careers-content .open-positions-heading {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 1.2em; } }

.contact-form .form-group {
  text-align: start; }
  .contact-form .form-group input {
    border: 1px solid #ced4da75 !important;
    background-color: rgba(247, 244, 244, 0.16) !important; }
  .contact-form .form-group textarea {
    border: 1px solid #ced4da75 !important;
    background-color: rgba(247, 244, 244, 0.16) !important; }

.contact-form .server-response {
  padding-top: 15px;
  font-weight: bold;
  font-size: 16px;
  width: 85%;
  margin-right: auto;
  margin-left: auto; }

.offices-group {
  text-align: center;
  margin-bottom: 5%; }
  .offices-group p {
    font-size: 0.8em; }
  .offices-group strong {
    font-weight: bolder;
    line-height: 1.5;
    font-size: 1.4em;
    font-weight: 700; }

@media only screen and (min-width: 580px) {
  .offices-group h1 {
    font-size: 3em; }
  .offices-group p {
    font-size: 1.1em; }
  .offices-group strong {
    font-size: 1.5em; } }

@media only screen and (min-width: 768px) {
  .offices-group p {
    font-size: 1.1em; }
  .offices-group strong {
    font-size: 1.5em; } }

.contact-content {
  position: relative;
  width: 100%;
  left: 0;
  top: 15%;
  background-color: #183858;
  background-image: url(/static/media/mobile-bg-2.a4d1bcac.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .contact-content .contact-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 35%;
    padding-bottom: 10%;
    margin: 0; }
    .contact-content .contact-row h1 {
      font-size: 2.4em;
      font-weight: 700; }
    .contact-content .contact-row .located-paragraph {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 70px; }
    .contact-content .contact-row .contact-col {
      flex: 1 1;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center; }
    .contact-content .contact-row .contact-info {
      margin-top: 50px;
      border-top: 0.8px solid #ced4da75; }
      .contact-content .contact-row .contact-info p {
        opacity: 1;
        margin-top: 20px; }
  @media only screen and (min-width: 768px) {
    .contact-content {
      top: 25%;
      background: transparent; }
      .contact-content .contact-row {
        padding-top: 15%; }
        .contact-content .contact-row h1 {
          font-size: 5em; }
        .contact-content .contact-row .contact-col {
          flex-direction: row; }
        .contact-content .contact-row .contact-info p {
          margin-top: 30px; } }

.contact-container {
  background-color: #03090e; }
  .contact-container video {
    opacity: 0.4; }

.we-like-to-keep-container {
  background-image: url(/static/media/bg.71ef24dc.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  background-size: cover;
  width: 100%;
  padding-bottom: 100px; }
  .we-like-to-keep-container .we-like-to-keep-content-wrapper {
    justify-content: center; }
  @media only screen and (min-width: 992px) {
    .we-like-to-keep-container {
      background-position: top;
      background-size: auto auto; } }

.team-banner-container {
  background-image: url(/static/media/team.77963297.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 15px; }
  .team-banner-container .team-banner-content-container .team-banner-heading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 190px;
    padding-top: 50px;
    text-align: left;
    font-size: 1.2em; }
    .team-banner-container .team-banner-content-container .team-banner-heading-container h1 {
      font-weight: 700;
      text-align: center;
      font-size: 2.4em;
      line-height: 1.5em;
      padding-bottom: 3%;
      line-height: 1.1em; }
    .team-banner-container .team-banner-content-container .team-banner-heading-container p {
      line-height: 1.5;
      font-size: 1rem;
      font-weight: 300;
      margin: 16px 0px 0px 0px;
      text-align: center; }
  .team-banner-container .team-banner-content-container .team-banner-common-things-heading {
    text-align: left;
    height: 110px; }
    .team-banner-container .team-banner-content-container .team-banner-common-things-heading h2 {
      font-size: 1.2em;
      font-weight: 700;
      line-height: 1.2em;
      text-align: center; }
  .team-banner-container .team-banner-content-container .team-banner-common-things-container .common-thing {
    text-align: left;
    margin-bottom: 20px; }
    .team-banner-container .team-banner-content-container .team-banner-common-things-container .common-thing h3 {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 1.2em;
      margin: 0.27em 0; }
    .team-banner-container .team-banner-content-container .team-banner-common-things-container .common-thing p {
      margin-top: 16px;
      font-size: 1rem;
      line-height: 1.5; }
  @media only screen and (min-width: 767px) {
    .team-banner-container {
      padding: 0;
      justify-content: center; }
      .team-banner-container .team-banner-content-container .team-banner-heading-container {
        height: 330px;
        margin-top: 50px; }
        .team-banner-container .team-banner-content-container .team-banner-heading-container h1 {
          font-size: 5em;
          line-height: 1em;
          padding-bottom: 3%; }
      .team-banner-container .team-banner-content-container .team-banner-common-things-heading {
        margin-bottom: 30px; }
      .team-banner-container .team-banner-content-container .team-banner-common-things-heading {
        text-align: left;
        height: 80px;
        margin-top: 0; }
        .team-banner-container .team-banner-content-container .team-banner-common-things-heading h2 {
          font-size: 2em;
          font-weight: 700;
          line-height: 1.2em; }
      .team-banner-container .team-banner-content-container .team-banner-common-things-container .common-thing {
        margin-bottom: 20px; } }
  @media screen and (min-width: 1024px) {
    .team-banner-container .team-banner-content-container .team-banner-common-things-heading {
      margin-bottom: 0px; } }
  @media only screen and (min-width: 1440px) {
    .team-banner-container {
      padding: 0;
      height: 100vh; }
      .team-banner-container .team-banner-content-container .team-banner-heading-container {
        padding: 0;
        margin-top: 0px; }
      .team-banner-container .team-banner-content-container .team-banner-common-things-heading {
        padding: 0; }
      .team-banner-container .team-banner-content-container .team-banner-common-things-container .common-thing h3 {
        font-size: 1.3em; }
      .team-banner-container .team-banner-content-container .team-banner-common-things-container .common-thing p {
        width: 330px; } }

.employee-container {
  flex: 1 1;
  margin-bottom: 50px;
  margin-top: 30px;
  flex-direction: column; }
  .employee-container .image-wraper img {
    width: 100%;
    max-width: 500px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover; }
  .employee-container .info span, .employee-container .info-reverse span {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.6; }
  .employee-container .info h2, .employee-container .info-reverse h2 {
    font-size: 17px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 20px; }
  .employee-container .info p, .employee-container .info-reverse p {
    text-align: justify;
    font-size: 14px; }
  @media only screen and (min-width: 580px) {
    .employee-container .info span, .employee-container .info-reverse span {
      font-size: 40px;
      font-weight: bold; }
    .employee-container .info h2, .employee-container .info-reverse h2 {
      font-size: 22px;
      line-height: 1.2;
      font-weight: 300;
      text-align: center; }
    .employee-container .info p, .employee-container .info-reverse p {
      margin-top: 5%; } }
  @media only screen and (min-width: 768px) {
    .employee-container {
      flex-direction: row;
      align-items: center; }
      .employee-container .image-wraper {
        text-align: left; }
        .employee-container .image-wraper img {
          width: auto;
          max-height: 500px;
          height: auto; }
      .employee-container .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center; }
        .employee-container .info span {
          font-size: 40px;
          font-weight: bold;
          line-height: 1.2; }
        .employee-container .info h2 {
          font-size: 22px;
          line-height: 1.2;
          font-weight: 300;
          text-align: left; }
        .employee-container .info p {
          margin-top: 0;
          font-size: 14px; } }
  @media only screen and (min-width: 1200px) {
    .employee-container .info span {
      font-size: 50px;
      font-weight: bold;
      line-height: 1.2; }
    .employee-container .info h2 {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 300; }
    .employee-container .info p {
      margin-top: 0; } }

@media only screen and (min-width: 768px) {
  .employee-container:nth-child(even) {
    flex-direction: row-reverse; }
    .employee-container:nth-child(even) .image-wraper {
      text-align: right; } }

.we-like-to-keep-banner-container {
  padding-top: 100px;
  padding-bottom: 70px; }
  .we-like-to-keep-banner-container .we-like-to-keep-heading {
    font-weight: 700;
    text-align: center;
    font-size: 2.4em;
    padding-bottom: 35px; }
  .we-like-to-keep-banner-container p {
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 300;
    text-align: center; }
  @media only screen and (min-width: 767px) {
    .we-like-to-keep-banner-container {
      padding-top: 200px;
      padding-bottom: 150px; }
      .we-like-to-keep-banner-container .we-like-to-keep-heading {
        line-height: 1em;
        padding-bottom: 50px; } }
  @media only screen and (min-width: 992px) {
    .we-like-to-keep-banner-container .we-like-to-keep-heading {
      font-size: 4em; } }

.we-like-to-keep-content {
  background-color: #282828;
  text-align: center;
  height: 348px;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex; }
  .we-like-to-keep-content img, .we-like-to-keep-content p {
    transition: opacity 0.5s ease-in-out;
    width: 225px;
    height: auto;
    align-self: center;
    margin-left: auto;
    margin-right: auto; }
  .we-like-to-keep-content p {
    top: 0;
    left: 0;
    opacity: 1;
    display: block;
    margin: auto;
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .we-like-to-keep-content img {
      width: 290px; } }
  @media only screen and (min-width: 1200px) {
    .we-like-to-keep-content img {
      width: 300px; } }

.case-banner {
  background-image: url(/static/media/work.acba2c41.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: auto; }
  .case-banner .case-banner-content {
    height: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto; }
    .case-banner .case-banner-content h1 {
      padding-top: 120px;
      padding-bottom: 50px;
      font-size: 2.4em;
      font-weight: bold; }
    .case-banner .case-banner-content p {
      padding-bottom: 50px; }
  @media screen and (min-width: 415px) {
    .case-banner .case-banner-content {
      width: 75%; }
      .case-banner .case-banner-content h1 {
        padding-bottom: 70px; } }
  @media screen and (min-width: 769px) {
    .case-banner .case-banner-content {
      width: 50%; }
      .case-banner .case-banner-content h1 {
        font-size: 5em;
        width: 600px; } }
  @media screen and (min-width: 1025px) {
    .case-banner .case-banner-content {
      height: 650px; }
    .case-banner p {
      padding: 0 50px; } }

.case-section-container {
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px; }
  .case-section-container .case-container-width .section-text-wrapper {
    width: 100%; }
    .case-section-container .case-container-width .section-text-wrapper .case-section-heading {
      color: #df5745;
      font-weight: 700;
      font-size: 1.5em;
      margin-bottom: 50px; }
    .case-section-container .case-container-width .section-text-wrapper .case-subheading-section {
      margin-bottom: 50px;
      font-size: 1em; }
    .case-section-container .case-container-width .section-text-wrapper p {
      font-size: 0.8em; }
  .case-section-container .case-container-width .img-case-desktop-1,
  .case-section-container .case-container-width .img-case-desktop-2,
  .case-section-container .case-container-width .img-lexus {
    width: 260px;
    height: auto; }
  .case-section-container .case-container-width .img-case-mobile {
    margin-top: 20px;
    width: auto;
    height: 330px; }
  .case-section-container:nth-child(odd) {
    background-color: #141a21;
    color: white; }
    .case-section-container:nth-child(odd) .case-container-width .reverse-row-direction {
      flex-direction: row-reverse; }
      .case-section-container:nth-child(odd) .case-container-width .reverse-row-direction .section-text-wrapper {
        padding-bottom: 70px; }
  @media screen and (min-width: 376px) {
    .case-section-container .case-container-width .img-case-desktop-1,
    .case-section-container .case-container-width .img-case-desktop-2,
    .case-section-container .case-container-width .img-lexus {
      width: 340px; }
    .case-section-container .case-container-width .img-case-mobile {
      height: 400px; } }
  @media screen and (min-width: 767px) {
    .case-section-container .case-container-width .section-text-wrapper p {
      font-size: 0.9em; }
    .case-section-container .case-container-width .img-case-desktop-1, .case-section-container .case-container-width .img-case-desktop-2, .case-section-container .case-container-width .img-lexus {
      width: 550px; }
    .case-section-container .case-container-width .img-case-mobile {
      margin-top: 30px;
      height: 470px; } }
  @media screen and (min-width: 992px) {
    .case-section-container {
      height: 750px;
      padding-top: 100px;
      padding-bottom: 100px; }
      .case-section-container .case-container-width {
        width: 1400px;
        max-width: 100%; }
        .case-section-container .case-container-width .section-text-wrapper {
          width: 85%; }
          .case-section-container .case-container-width .section-text-wrapper .case-section-heading {
            font-size: 40px;
            line-height: 1em; }
          .case-section-container .case-container-width .section-text-wrapper .case-subheading-section {
            font-size: 1.5em; }
          .case-section-container .case-container-width .section-text-wrapper p {
            font-size: 1em; }
        .case-section-container .case-container-width .img-case-desktop-1, .case-section-container .case-container-width .img-case-desktop-2, .case-section-container .case-container-width .img-lexus {
          width: 450px; }
        .case-section-container .case-container-width .img-case-mobile {
          height: 480px; }
      .case-section-container:nth-child(3) {
        height: 600px; }
        .case-section-container:nth-child(3) .case-container-width .reverse-row-direction img {
          margin-top: -150px; }
      .case-section-container:nth-child(5) {
        height: 600px; }
        .case-section-container:nth-child(5) .case-container-width .reverse-row-direction img {
          margin-top: -210px; } }
  @media screen and (min-width: 1200px) {
    .case-section-container {
      height: 700px; }
      .case-section-container .case-container-width .section-text-wrapper .case-section-heading {
        font-size: 52px; }
      .case-section-container .case-container-width .section-text-wrapper .case-subheading-section {
        font-size: 1.6em; }
      .case-section-container .case-container-width .img-case-desktop-1,
      .case-section-container .case-container-width .img-case-desktop-2,
      .case-section-container .case-container-width .img-lexus {
        width: 650px; }
      .case-section-container .case-container-width .img-case-mobile {
        height: 600px; } }

* {
  box-sizing: border-box; }

#notfound {
  position: relative;
  height: 100vh; }
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding: 15px; }
  .notfound .notfound-404 {
    position: relative;
    height: 220px; }
    .notfound .notfound-404 h1 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 186px;
      font-weight: 600;
      margin: 0px;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      color: #183858; }
  .notfound h2 {
    font-family: inherit;
    font-size: 33px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    letter-spacing: 3px;
    font-family: 'Montserrat', sans-serif; }
  .notfound p {
    font-family: 'Kanit', sans-serif;
    font-size: 16px;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom: 25px; }
  .notfound a {
    font-family: 'Kanit', sans-serif;
    color: white;
    font-weight: 400;
    text-decoration: none;
    border-radius: 2px;
    display: inline-block;
    clear: both;
    background: #00ccff;
    border-radius: 60px;
    padding: 22px 41px;
    font-family: 'Montserrat', sans-serif; }

.notfound-social > a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: #ff6f68;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin: 3px;
  transition: 0.2s all; }
  .notfound-social > a:hover {
    color: #fff;
    background-color: #ff6f68;
    border-color: #ff6f68; }

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    position: relative;
    height: 168px; }
    .notfound .notfound-404 h1 {
      font-size: 142px; }
  .notfound h2 {
    font-size: 22px; } }

.error-header-color {
  background-color: #0e1323; }

