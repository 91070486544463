@import '../../variables.scss';

.case-section-container {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;

    .case-container-width {
        .section-text-wrapper {
            width: 100%;
        
            .case-section-heading {
                color: $primary-color-red;
                font-weight: 700;
                font-size: 1.5em;
                margin-bottom: 50px;
            }
            .case-subheading-section {
                margin-bottom: 50px;
                font-size: 1em;
            }
            
            p {
                font-size: 0.8em;
            }
        }
        .img-case-desktop-1, 
        .img-case-desktop-2,
        .img-lexus {
            width: 260px;
            height: auto;
        }
        .img-case-mobile {
            margin-top: 20px;
            width: auto;
            height: 330px;
        }
    }

    

    &:nth-child(odd) {
        background-color: #141a21;
        color: white;

        .case-container-width {
            .reverse-row-direction {
                flex-direction: row-reverse;
        
                .section-text-wrapper {
                    padding-bottom: 70px;
                }
            }
        } 
    }

    @media screen and (min-width: 376px) {
        .case-container-width { 
            .img-case-desktop-1, 
            .img-case-desktop-2, 
            .img-lexus {
                width: 340px;
            }
            .img-case-mobile {
                height: 400px;
            }
        }
    }

    @media screen and (min-width: 767px) {
        .case-container-width {
            .section-text-wrapper {
                p {
                    font-size: 0.9em;
                }
            }
            .img-case-desktop-1, .img-case-desktop-2, .img-lexus {
                width: 550px;
            }
            .img-case-mobile {
                margin-top: 30px;
                height: 470px;
            }
        }
    }
    
    @media screen and (min-width: 992px) {
        height: 750px;
        padding-top: 100px;
        padding-bottom: 100px;

        .case-container-width {
            width: 1400px;
            max-width: 100%;
            .section-text-wrapper {
                width: 85%;
            
                .case-section-heading {
                    font-size: 40px;
                    line-height: 1em;
                }

                .case-subheading-section {
                    font-size: 1.5em;
                }
    
                p {
                    font-size: 1em;
                }
            }
            .img-case-desktop-1, .img-case-desktop-2, .img-lexus {
                width: 450px;
            }
            .img-case-mobile {
                height: 480px;
            }
    
        }
        
        &:nth-child(3) {
            height: 600px;
            .case-container-width {
                .reverse-row-direction {

                    img {
                        margin-top: -150px;
                    }
                }
            }
        }
        &:nth-child(5) {
            height: 600px;
            .case-container-width {
                .reverse-row-direction {
                    img {
                        margin-top: -210px;
                    }
                }
            }
        }
        
        
    }
    @media screen and (min-width: 1200px) {
        height: 700px;

        .case-container-width {
            .section-text-wrapper {
                .case-section-heading {
                    font-size: 52px;
                }
                .case-subheading-section {
                    font-size: 1.6em;
                }
            }

            .img-case-desktop-1,
            .img-case-desktop-2,
            .img-lexus {
                width: 650px;
            }
            .img-case-mobile {
                height: 600px;
            }
        }
    }
}