@import '../../../variables.scss';

.we-like-to-keep-banner-container {
    padding-top: 100px;
    padding-bottom: 70px;

    .we-like-to-keep-heading {
        font-weight: 700;
        text-align: center;
        font-size: 2.4em;
        padding-bottom: 35px;
    }
    p {
        line-height: 1.5;
        font-size: 1rem;
        font-weight: 300;
        //font-family: 'Open Sans', sans-serif;
        text-align: center;
    }

    @media only screen and (min-width: 767px) { 
        padding-top: 200px;
        padding-bottom: 150px;

        .we-like-to-keep-heading {
            line-height: 1em;
            padding-bottom: 50px;
        }
    }

    @media only screen and (min-width: 992px) {
        .we-like-to-keep-heading {
            font-size: 4em
        }
    }
}
