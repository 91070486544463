@import '../../variables.scss';

.careers-content {
    background-color: #f9f9f9;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 4%;
    
    .open-positions-heading {
        //font-family: $montserratFont;
        font-size: 1.1em;
        text-align: left;
        // text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 5%;
        margin-top: 10%;
        padding-bottom: 3%;

    }

    @media only screen and (min-width: 768px) {
        .open-positions-heading {
            margin-bottom: 0;
            margin-top: 0;
            font-size: 1.2em;
        }
    }
}
