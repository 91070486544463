footer {
    position: relative;
    width: 100%;
    height: auto;
    clear: both;
    background-color: #f5f5f5;
    
    .footer-links {
        margin-top: 20px;
            a {
                list-style: none;
                display: block;
                margin: 15px 0px;
                font-size: 16px;
                line-height: 27px;
                color: #969696;
                font-weight: 300;
            }
    }

    .footer-logo {
        margin: 10px 0;
        
        
        img {
            height: auto;
            width: 25%;
            min-width: 150px;
        }
    }

    @media only screen and (min-width: 769px) {
        height: 170px;

        .footer-links {
            margin-top: 40px;
            a {
                display: inline;
                margin: 0 25px;

            }
        }
        .footer-logo {
            margin: 20px 0;
            
            
            img {
                height: auto;
                width: 25%;
            }
        }
    }
}