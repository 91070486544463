@import '../../../../src/variables';

.header {
    width: 100%;
    z-index: 9;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;

    #myHeader {
        height: 60px;
        padding-top: 16px;

        .site-branding {
            text-align: left;
    
            img {
                width: 100%;
                max-width: 150px;
                height: auto;
            }
        }
        
        .toggle-btn {
            color: white;
            position: absolute;
            right: 20px;
            top: 0;
        }
    }
    .sidenav {
        height: 100%;
        width: 100%;
        position: fixed;
        display: flex;
        top: -100%;
        right: -100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: $primary-color-red;
        overflow-x: hidden;
        
        .close-btn {
            position: absolute;
            right: 25px;
            top: 10px;
        }
        .link-container{
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }

        a {
            text-decoration: none;
            font-size: 2.5em;
            color: white;
            display: block;
            //font-family: $montserratFont;
            font-weight: 700;

        }
        a:hover{
            color: #cccccc;
        }
    }

    .openNav {
        right: 0;
        top: 0;
        transition-duration: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
        display: flex;
    }
    .closeNav {
        right: -100%;
        transition-duration: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -o-transition: 0.2s;
    }

    @media screen and (min-width: 580px) {
        #myHeader {
            .site-branding {
                img {
                    max-width: 250px;
                }
            }
        }
        .sidenav {
            a { 
                font-size: 2.7em;
            }
        }
    }
    
    @media screen and (min-width: 768px) {
        #myHeader {
            .site-branding {
                img {
                    max-width: 300px;
                }
            }
        }
        .sidenav {
            height: 100%;
            justify-content: center;
            a {
                font-size: 1.6em;
            }
            .link-container {
                height: auto;
                width: 90%;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
            }
        } 
    }

    @media screen and (min-width: 1024px) {
        #myHeader {
            .site-branding {
                img {
                    max-width: 270px;
                }
            }
        }
        .sidenav {
            a { 
                font-size: 2.1em;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        // .toggle-btn {
        //     top: 25px;
        // }
        .sidenav {
            a { 
                font-size: 2.5em;
            }
        }
    }
    
    @media screen and (min-width: 1440px) {
        // .toggle-btn {
        //     top: 30px;
        // }
        .sidenav {
            a { 
                font-size: 2.8em;
            }
        }
    }

    @media screen and (min-width: 1700px) {
        // .toggle-btn {
        //     top: 30px;
        // }
        .sidenav {
            a { 
                font-size: 3em;
            }
        }
    }
    
}

.sticky {
    position: fixed;
    top: 0;
    background-color: rgb(14, 19, 35);
    // padding-bottom: 5px;
    animation: smoothScroll 1.2s forwards;
    @keyframes smoothScroll {
        0% {
            transform: translateY(-100px);
        }
        100% {
            transform: translateY(0px);
        }
    }
}

.dissapeared {
    position: fixed;
    background-color: #0e13239e;
    animation: smoothScroll 1.2s forwards;
    @keyframes smoothScroll {
        0% {
            transform: translateY(-100px);
        }
        100% {
            transform: translateY(0px);
        }
    }
}