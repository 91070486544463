@import '../../../variables.scss';

.about-banner-container {
    background-image: url("../../../assets/img/about-1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding: 5% 0;

    .about-banner-text-container {
        display: flex;
        flex-direction: column;
        //justify-content: space-around;
        align-items: center;
        padding-top: 50px;

        .about-banner-box-container {

            .about-banner-box {
                display: flex;
                flex-direction: column;
            }

        }

        h1 {
            font-weight: 700;
            font-size: 2.4em;
            padding-bottom: 2%;
            margin-top: 10%;
            text-align: center;
        }

        h3 {
            text-align: center;
            font-size: 1.1em;
            // text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 15px;
            padding: 5px;
            word-spacing: 9999px;
        }
        p {
            line-height: 1.5;
            font-size: 1rem;
            font-weight: 300;
            //font-family: 'Open Sans', sans-serif;
            margin-bottom: 5%;
            // padding: 0 100px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            
        }

        .subheader {
            margin-top: 30%;
            margin: 0;
            margin-bottom: 30px;
            
            h2 {
                // text-transform: uppercase;
                font-weight: 700;
                font-size: 1.2em;
            }
        }
    }
  @media only screen and (min-width: 768px) {
    //   height: 100vh;
      padding-top: 0;
      padding-bottom: 60px;

      .about-banner-text-container{
        padding-top: 0;

        h1 {
            font-size: 5rem;
            max-width: 600px;
            display: table;
            margin: 0 auto;
            margin-top: 150px;
        }

        .subheader h2 {    
            margin-bottom: 60px;
            font-size: 2rem;
            padding-top: 50px;
        }

        h3 {
                font-size: 1.5rem;
        }
      }
  }
}
