@import '../../../variables.scss';


//  START Rippling ATS Listing Widget 
.careers-content .open-positions-heading {
  color: #183858;
}
@media only screen and (min-width: 768px) {
  .careers-content .open-positions-heading {
    font-size: 2.2em!important;
    text-align: center!important;
  } 
}
#hiringthing-jobs .ht-summary {
  max-width: 100%;
}
#hiringthing-jobs .ht-location {
  font-size: 1em;
  margin-bottom: 10px;
}
#hiringthing-jobs .ht-apply-link {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 16px;
  background-color: #17a2b8;
  border-color: #17a2b8;
  padding: 6px 12px;
  color: white;
  border-radius: 3px;

  &:hover {
    text-decoration: none!important;
    background-color: #138496;
    border-color: #117a8b;
  }
}
#hiringthing-jobs .ht-title-link {
  display: block;
  font-size: 1.4em;
  font-weight: 500;
  color: #183858;
  padding-top: 35px;
  box-shadow: 0 -4px 4px -4px lightgrey;

  &:hover {
    text-decoration: none!important;
  }

  &:first-child {
    box-shadow: none;
  }
}
// END Rippling ATS Listing Widget 

.open-positions {
  margin-bottom: 7%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .error-message { 
    color: red;
  }

    .position-list {
      text-align: left;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .position-wrapper{
        background-color: white;
        margin: 1% 1%;
        -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.11);
        -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.11);
        box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.08);
        
        .position {
          margin: 4%;
        
          img {
            width: auto;
            max-width: 200px;
          }
          
          p {
            font-size: 0.9em;
            line-height: 1.6;
            font-weight: 400;
          }
          
          .position-title {
            font-size: 1.4em;
            font-weight: 700;
            margin-top: 30px;
          }

          .resposibilities, .skills, .bonus {
            margin: 2% 0;

            .list-header {
              font-size: 1em;
              font-weight: 700;
            }

            .skills-title{
              font-size: 1em;
              font-weight: 500;
            }
            
            .careers-list {
              list-style: none;
              padding-inline-start: 10px;
              font-size: 0.9em;
              line-height: 30px;
              font-weight: 400;
            }
          }

    

        .btn-wrapper{
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: center;
        

          .apply-btn {
            background-color: #4bc1d3;
            align-self: center;
            margin-top: 15px;
            border: solid 1px #3ca9b7;
          }
          .apply-btn:hover {
            background-color: #3ca9b7;
            align-self: center;
            border: solid 1px #4bc1d3;
          }
        }
      }
    }
  }
 

  @media only screen and (min-width: 768px) {
    flex-direction: column;
    .position-list {
      flex-direction: row;
    }
  }
}