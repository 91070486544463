.testimonial-container {
    padding-bottom: 30px;

    .center-testimonial {
        padding-left: 3%;
        padding-right: 3%;
        margin: 0 auto;

        .testimonial-body {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    @media screen and (min-width: 769px) {
        padding-top: 80px;
        padding-bottom: 80px;

    }
}