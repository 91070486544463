@import '../../../variables.scss';

.offices-group {
  text-align: center;
  margin-bottom: 5%;

  p {
    font-size: 0.8em;
  }

  strong {
    font-weight: bolder;
    line-height: 1.5;
    font-size: 1.4em;
    font-weight: 700;
  }
}
@media only screen and (min-width: 580px){
  .offices-group {
    h1 {
      font-size: 3em;
    }

    p {
      font-size: 1.1em;
    }

    strong {
      font-size: 1.5em;
    }
  }
} 
@media only screen and (min-width: 768px){
  .offices-group {
    
    p {
      font-size: 1.1em;
    }

    strong {
      font-size: 1.5em;
    }
  } 
}