@import '../../../variables.scss';

.info-peek-about-wrapper {
    height: auto;
    background-image: url("../../../assets/img/mobile-bg-2.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //font-family: 'Montserrat', sans-serif;

    .info-peek-about {
        // top: 27%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 100px;
        //font-family: $montserratFont;

        
        a {
            // text-transform: uppercase;
            padding: 10px 20px;
            font-size: 0.8em;

        }

        .info-peek-about-title {
            // text-transform: uppercase;
            font-size: 1.8em;
            font-weight: 700;
        }

        .info-peek-about-content {
            margin-bottom: 2%;
            margin-top: 1%;
            font-size: 1.1em;
            padding-left: 2%;
            padding-right: 2%;
            font-size: 0.8em;
            

            h2 { font-size: 3.5em; }
        }
    }

    @media only screen and (min-width: 580px) {
            .info-peek-about {
                // max-width: 850px;

                .info-peek-about-title {
                    font-size: 2em;
                }
                
                .info-peek-about-content { 
                    font-size: 1.1em; 
                }
            }
        }
    @media only screen and (min-width: 768px) {
            .info-peek-about {
                max-width: 850px;
                height: 80vh;
                a { font-size: 1.1em; }
                
                .info-peek-about-title { font-size: 3.5em; }
                
                .info-peek-about-content { font-size: 1.1em; }
            }
        }
    }