.transform-container {
    padding-top: 50px;
    padding-bottom: 30px;

    .transform-heading {
        font-weight: bold;
        font-size: 2em;
        margin-bottom: 50px;
        font-size: 1.1em;
        margin-bottom: 20px;
    }

    h4 {
        padding-bottom: 50px;
        padding-top: 50px;
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }

    @media screena and (min-width: 415px) {
        .transform-heading {
            font-size: 1.4em;
        }
    }
    @media screen and (min-width: 769px) {
        padding-top: 70px;
        padding-bottom: 50px;
    }

    @media screen and (min-width: 992px) {
        padding-top: 70px;
        padding-bottom: 50px;
        .transform-heading {
            font-size: 2em;
            margin-bottom: 50px;
        }
    }
}