@import '../../../variables.scss';

a#hover-button-effect {
    position: relative;
    overflow: hidden;
    background: $primary-color-yellow;
    border: none;
    padding: 17px 27px;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    color: white;
  }

  
#hover-button-effect .button-text {
    position: absolute;
    opacity: 0;
    color: white;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-top: 18px;
    z-index: 1000;
  } 

  a#hover-button-effect:hover .button-text {
    opacity: 1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.2s;
  }

  #hover-button-effect .button-hover-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: -100%;
    top: 0;
    opacity: 0;
    background-color: $primary-color-red;
    z-index: 10;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  a#hover-button-effect:hover .button-hover-bg {
    width: 100%;
    height: 100%;
    left: 0%;
    opacity: 1;
    position: absolute;
    padding: 10px 20px;
    background-color: $primary-color-red;
    border: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }