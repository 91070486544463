@import '../../../variables.scss';

.careers-sidebar {

    text-align: left;
    margin-bottom: 8%;
    margin-bottom: 0;
    padding: 10px 20px;

    span {
        color: #4bbfd2;
    }

    .careers-sidebar-heading {
        // text-transform: uppercase;
        //font-family: $montserratFont;
        font-weight: 700;
        font-size: 1.8em;
        // margin-bottom:s 7%;
        // margin-top: 3%;
    }

    .sidebar-text {
        font-weight: 300;
        margin-bottom: 4%;
        font-size: 1em;
    }
    
    .benefits-list-container {
        background-color: #4bc1d3; 
        border-radius: 25px;
        color: white;
        margin-top: 30px;
        // margin-bottom: 60px;
        // padding: 35px 50px 0px 50px;
        margin-bottom: 0px;
        padding: 25px 50px 0px 25px;

        .benefits-list-heading {
            // text-transform: uppercase;
            font-weight: 700;
            font-size: 1.2em;
            //font-family: $montserratFont;
        }
        .benefits-list {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-left: 0;
            margin-top: 3%;

            li {
                padding-bottom: 10px;
                border-bottom: #5fc8d8 1px solid; 
                margin-bottom: 20px;
                font-size: 1em;
                display: inline-block;
                width: 100%;
            }
            svg {
                margin-right: 2%;
            }
        }
    }
    
    @media screen and (min-width: 767px) {   
        .benefits-list-container {
            margin-bottom: 60px;
            padding: 35px 50px 0px 50px;
        }    
        .careers-sidebar-heading {
            font-size: 3.2em;
        }
    
        .sidebar-text {
            font-size: 1em;
        }

        .benefits-list-container {
            margin-top: 0px;
            padding: 35px 50px 0px 50px;

            .benefits-list {
                flex-direction: row;
                flex-wrap: wrap;

                li {
                    width: 50%;
                }
            }
        }
    }
}


