.employee-container{
    flex: 1;
    margin-bottom: 50px;
    margin-top: 30px;
    flex-direction: column;
    
    .image-wraper{
        img {
            width: 100%;
            max-width: 500px;
            height: auto;
            // background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .info, .info-reverse{

        span {
            font-size: 25px;
            font-weight: bold;
            line-height: 1.6;
            //font-family: 'Montserrat', sans-serif;
        }
        h2 {
            font-size: 17px;
            line-height: 1.2;
            font-weight: 500;
            margin-bottom: 20px;
        }
        p {
            text-align: justify;
            font-size: 14px;
        }
    }
    @media only screen and (min-width: 580px) {
        .info, .info-reverse{
            span {
                font-size: 40px;
                font-weight: bold;
                // line-height: 1.4;
            }
            h2 {
                font-size: 22px;
                line-height: 1.2;
                font-weight: 300;
                text-align: center;
            }
            p {
                margin-top: 5%;
            }
        }
    }
    
    @media only screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;

        .image-wraper {
            text-align: left;
            
            img {
                width: auto;
                max-height: 500px;
                height: auto;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            span {
                font-size: 40px;
                font-weight: bold;
                line-height: 1.2;
            }
            h2 {
                font-size: 22px;
                line-height: 1.2;
                font-weight: 300;
                text-align: left;
            }
            p {
                margin-top: 0;
                font-size: 14px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .info{
            span {
                font-size: 50px;
                font-weight: bold;
                line-height: 1.2;
            }
            h2 {
                font-size: 30px;
                line-height: 1.2;
                font-weight: 300;
            }
            p {
                margin-top: 0;
            }
        }
    }
}

.employee-container:nth-child(even) {
    @media only screen and (min-width: 768px) {
        flex-direction: row-reverse;
        .image-wraper {
            text-align: right;
        }
    }
}