@import '../../../variables.scss';

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  //height: 100vh;
  background-image: url("../../../assets/img/mobile-bg-2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;

  video {
    opacity: 0.4;
  }

  .home-main-section {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .heading {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      p {
        font-weight: 700;
        line-height: 1.1em;
        margin: .27em 0;
        font-size: 2.4em;
        //font-family: $montserratFont;
      }
      
    }
    .home-content-section {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      
      h3 {
        font-weight: 700!important;
        line-height: 1.5em;
        // text-transform: uppercase;
        font-size: 0.9em;
      }
    }
    
  }

  @media only screen and (min-width: 415px) {
    .home-main-section {
      .heading {
        p {
          font-size: 2.5em;
          max-width: 350px;
          display: table;
          margin-left: auto ;
          margin-right: auto ;
        }
      }
      .home-content-section { 
        width: 65%;
      }
    }
  }

  @media only screen and (min-width: 520px) {
    .home-main-section {
      .heading {
        p { 
          font-size: 3.5em;
          max-width: 450px;
        }
      }
      .home-content-section { 
        width: 65%;
      }
    }
  }
  @media only screen and (min-width: 838px) {
    .home-main-section {
      .heading { 
        width: 60%;
      }
      .home-content-section {
        h3 {
          font-size: 1.3em;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    background: none;
    background-color: $primary-video-bg-color;
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    
    .home-main-section {
      .heading { 
        width: 65%;
      }
      .home-content-section {
        h3 {
          font-size: 1.3em;
        }
      }
    }
  }
  @media only screen and (min-width: 1256px) {
    .home-main-section {
      .heading {         
        p { 
          font-size: 5.2em;
          max-width: 550px;
        }
      }
      .home-content-section {
        width: 38%;
      }
    }
  }
}