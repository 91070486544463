@import '../../../variables.scss';

.case-banner {
    background-image: url("../../../assets/img/work.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    height: auto;

    .case-banner-content {
        height: auto;
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;

        h1 {
            padding-top: 120px;
            padding-bottom: 50px;
            font-size: 2.4em;
            font-weight: bold;
            // text-transform: uppercase;
        }
        p {
            padding-bottom: 50px;
        }
    }
    @media screen and (min-width: 415px) {
        .case-banner-content {
            width: 75%;

            h1 {
                padding-bottom: 70px;
            }
        }
    }

    @media screen and (min-width: 769px) {
        .case-banner-content {
            width: 50%;

            h1 {
                font-size: 5em;
                width: 600px;
            }
        }
    }
    
    @media screen and (min-width: 1025px) {
        .case-banner-content {
            height: 650px;
        }
        p {
            padding: 0 50px;
        }
    }
}