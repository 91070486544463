* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#notfound {
  position: relative;
  height: 100vh;
  .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding: 15px;
  .notfound-404 {
    position: relative;
    height: 220px;
    h1 {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 186px;
      font-weight: 600;
      margin: 0px;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      color: #183858;
    }
  }
  h2 {
    font-family: inherit;
    font-size: 33px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    letter-spacing: 3px;
    font-family: 'Montserrat', sans-serif;
  }
  p {
    font-family: 'Kanit', sans-serif;
    font-size: 16px;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  a {
    font-family: 'Kanit', sans-serif;
    color: white;
    font-weight: 400;
    text-decoration: none;
    border-radius: 2px;
    display: inline-block;
    clear: both;
    background: #00ccff;
    border-radius: 60px;
    padding: 22px 41px;
    font-family: 'Montserrat', sans-serif;
  }
}

.notfound-social > a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: #ff6f68;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin: 3px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  &:hover {
    color: #fff;
    background-color: #ff6f68;
    border-color: #ff6f68;
  }
}

@media only screen and (max-width: 480px) {
  .notfound {
    .notfound-404 {
      position: relative;
      height: 168px;
      h1 {
        font-size: 142px;
      }
    }
    h2 {
      font-size: 22px;
    }
  }
}

.error-header-color {
  background-color: rgb(14, 19, 35);
}
