.contact-form {
  .form-group {
    text-align: start;
    input {
      border: 1px solid #ced4da75!important;
      background-color: rgba(247, 244, 244, 0.16) !important;
    }
    textarea {
      border: 1px solid #ced4da75!important;
      background-color: rgba(247, 244, 244, 0.16) !important;
    }
  }
  .server-response {
    padding-top: 15px;
    font-weight: bold;
    font-size: 16px;
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
}