@import '../../../variables.scss';

.app-dev-container {
    height: auto;
    position: relative;
    top: -1px;
    display: flex;
    z-index: 1;
    background-color: white;

    .app-development-cards {
        margin-top: 50px;
        margin-bottom: 50px;

        .app-dev-box1 {
            text-align: center;
            border-bottom: 1px solid #EBEBEB;

            svg {
                font-size: 2.5em;
                float: left;
                color: #183858;
                transform: rotate(90deg);
            }
            .app-dev-left-title {
                margin-top: 50px;
                font-size: 1.5m;
                font-weight: 700;
                color: #183858;
                margin-bottom: 0;
                line-height: 1.2em;
            }
            .app-dev-left-content {
                margin-top: 10%;
                // margin-bottom: 28%;
                font-weight: 300;
                font-size: 1rem;
                line-height: 1.5;
                width: 92%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .app-dev-box {
            min-width: 200px;
            width: 100%;
            display: flex;
            flex: 1;
            flex-direction: column;
            font-size: 1em;
            height: 100%;
            margin-top: 15%;
        
            svg {
                font-size: 1.7em;
                margin-left: 4%;
            }
        
            .app-development-title {
                margin-bottom: 0.27em;
                margin-top: 14%;
                font-weight: 700;
                line-height: 1.2em;
                font-size: 1.2em;
            }
        
            .app-development-content {
                margin-top: 2%;
                font-weight: 300;
                font-size: 1rem;
                line-height: 1.5;
            }
        }
        .hl-dev {
            width: 15%;
            background-color: white;
            height: 2px;
        }
    }
    @media only screen and (min-width: 415px) {
        .app-development-cards {
                .app-dev-box1 {
                    .app-dev-left-title{
                        font-size: 2em;
                    }
                }
            .app-dev-box {
                margin-top: 10%;
            }
        }
    }
    @media only screen and (min-width: 580px) {
        .app-development-cards {
                .app-dev-box1 {
                    .app-dev-left-title{
                        font-size: 2.6em;
                    }
                }
        }
    }
    @media only screen and (min-width: 769px) {
        .app-development-cards {
            margin-top: 100px;
            margin-bottom: 100px;

            .app-dev-box1 { 

                svg { font-size: 3.5em; }

                .app-dev-left-title { 
                    font-size: 2.4em;
                    @media only screen and (min-width: 1399px) {
                        font-size: 3.6em;
                    }
                }
                .app-dev-left-content {
                    margin-top: 3%;
                    margin-bottom: 6%;
                    width: 69%;
                }
            }

            .app-dev-box {
                margin-top: 30%;
                svg { font-size: 1.9em; }
            }
        }
    }
}
