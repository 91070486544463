@import '../../../variables.scss';

.contact-content {
  position: relative;
  width: 100%;
  left: 0;
  top: 15%;
  background-color: $primary-color-blue-dark;
  background-image: url("../../../assets/img/mobile-bg-2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .contact-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 35%;
    padding-bottom: 10%;
    margin: 0;

    h1 {
      font-size: 2.4em;
      font-weight: 700;
    }
    .located-paragraph {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 70px;
    }

    .contact-col {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
    }
    .contact-info {
      margin-top: 50px;
      border-top: 0.8px solid #ced4da75;
            
      p {
        opacity: 1;
        margin-top: 20px; 
      }
    }
  }
  
  @media only screen and (min-width: 768px) {
    top: 25%;
    background: transparent;
    
    .contact-row {
      padding-top: 15%;

      h1 {
        font-size: 5em;
      }

      .contact-col {
        flex-direction: row;
      }
      .contact-info {
        p {
          margin-top: 30px; 
        }
      }
    }
  }
}