@import '../../../variables.scss';

.team-banner-container {
    background-image: url("../../../assets/img/team.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 0 15px;

    .team-banner-content-container{
        .team-banner-heading-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 190px;
            padding-top: 50px;
            text-align: left;
            font-size: 1.2em;
            
            
            h1 {
                font-weight: 700;
                text-align: center;
                font-size: 2.4em;
                line-height: 1.5em;
                padding-bottom: 3%;
                line-height: 1.1em;
            }
            
            p {
                line-height: 1.5;
                font-size: 1rem;
                font-weight: 300;
                margin: 16px 0px 0px 0px;
                //font-family: 'Open Sans', sans-serif;
                text-align: center;
            }
        }
        
        .team-banner-common-things-heading {
            text-align: left;
            height: 110px;
            
            h2 {
                font-size: 1.2em;
                font-weight: 700;
                line-height: 1.2em;
                text-align: center;
                // text-transform: uppercase;
            }
        }

        .team-banner-common-things-container {
            .common-thing {
                text-align: left;
                margin-bottom: 20px;
                h3 {
                    font-weight: 700;
                    font-size: 1.1em;
                    line-height: 1.2em;
                    margin: 0.27em 0;
                    // text-transform: uppercase;
                }
                p {
                    margin-top: 16px;
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }
        }
    }

    @media only screen and (min-width: 767px) {
        padding: 0;
        justify-content: center;
        .team-banner-content-container{

            .team-banner-heading-container {
                height: 330px;
                margin-top: 50px;
        
                h1 {
                    font-size: 5em;
                    line-height: 1em;
                    padding-bottom: 3%;
                }
            }
            .team-banner-common-things-heading {
                margin-bottom: 30px;
            }
            
    
            .team-banner-common-things-heading {
                text-align: left;
                height: 80px;
                margin-top: 0; 
        
                h2 {
                    font-size: 2em;
                    font-weight: 700;
                    line-height: 1.2em;
                }
            }
        
            .team-banner-common-things-container {
                .common-thing {
                    margin-bottom: 20px;
                    
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .team-banner-content-container{
            .team-banner-common-things-heading {
                margin-bottom: 0px;
            }
            
        }
    }
    @media only screen and (min-width: 1440px) {
        padding: 0;   
        height: 100vh;
        .team-banner-content-container{

            .team-banner-heading-container {
                // height: 330px;
                padding: 0;
                margin-top: 0px;
            }
            .team-banner-common-things-heading {
                padding: 0;
            }
        

            .team-banner-common-things-container {
                .common-thing {
                    h3 {
                        font-size: 1.3em;
                    }
                    p {
                        width: 330px;
                    }
                }
            }
        }
    }
   
}