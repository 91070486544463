@import '../../../variables.scss';

.services-container {
    height: auto;
    position: relative;
    //top: -10px;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
    background-color: #f9f9f9;
    
    .consulting-services-heading {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding: 6% 0;
        margin: 1% 0;
        color: $primary-color-blue-dark;
        
        h1 {
            font-weight: 700;
            line-height: 1.2em;    
            font-size: 1.5em;

            span { 
                display: block;
                margin-top: 10px;
                font-size: 0.7em; 
            }
        } 
    }
    .consulting-services-box-wrapper {
        height: 100%;
        min-width: 200px;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-size: 1em;
        padding: 7% 6% 0 6%;
        font-size: 1em;
        text-align: left;

        svg {
            font-size: 2.5rem;
            // margin-bottom: 8%;
        }

        .consulting-services-box {
            padding: 11% 0;
            margin-bottom: 5%;

            .consulting-services-box-title {
                font-weight: 700;
                margin-top: 10%;
                margin-bottom: 3%;
                font-size: 1.8em;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        width: 100%;
        padding: 100px;
        
        .consulting-services-heading {
            padding: 3% 0;
            h1 { 
                font-size: 2.2em;
            }   
        }
        .consulting-services-box-wrapper {
            svg {
                margin-bottom: 8%;
            }
    
            .consulting-services-box {
                .consulting-services-box-title {
                   font-size: 1.6em;
                   max-width: 150px;
                }
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .consulting-services-heading {
            h1 { 
                font-size: 2.2em;
            }   
        }
        .consulting-services-box-wrapper {
            .consulting-services-box {
                .consulting-services-box-title {
                   font-size: 1.8em;
                   max-width: 150px;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .consulting-services-heading {
            h1 { 
                font-size: 2.2em;
            }   
        }
        .consulting-services-box-wrapper {
            .consulting-services-box {
                .consulting-services-box-title {
                   font-size: 2.2em;
                   max-width: 150px;
                }
            }
        }
    }
}