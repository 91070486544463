@import '../../../variables.scss';

.about-text-container {
    height: auto;
    padding-bottom: 14%;
    display: flex;
    flex-direction: column;
    text-align: left;

    h2 {
        font-size: 1.2em;
        font-weight: 700;
        // text-transform: uppercase;
        max-width: 250px;
        padding: 20px 0;
    }
    
    @media only screen and (min-width: 415px) {
        padding-bottom: 7%;
        h2 { 
            max-width: 350px;
            padding: 40px 0;
            font-size: 2em;
            // padding: 8% 0;
        }
    }
    
    @media only screen and (min-width: 769px) {
        padding-bottom: 6%;
        h2 {
            max-width: 500px;
            font-size: 2.6em;
            padding: 60px 0;
            // padding-top: 11%;
            // padding-bottom: 4%;
        }
    }
    
}